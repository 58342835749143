import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';

let loginLanguage = "en";

const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';

if (isGermanTenderEnv) {
  loginLanguage = 'de';
}

class Auth {

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.redirectUrl,
    audience: AUTH_CONFIG.audience,
    responseType: 'token id_token',
    scope: 'email openid',
    language: loginLanguage,
    overrides: {
      __tenant: AUTH_CONFIG.overrides.tenant,
      __token_issuer: AUTH_CONFIG.overrides.token_issuer
    },
  });

  constructor(history, setAuthAction, clearAuthAction) {
    this.history = history;
    this.setAuthAction = setAuthAction;
    this.clearAuthAction = clearAuthAction;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.authResult = authResult;
        this.setSession(authResult);
        this.setAuthAction(authResult.idToken);
      } else if (err) {      
        this.login();
        console.log(`ERROR: `, err);
      }else{
        this.login();
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         console.log(`Could not get a new token (${err.error}: ${err.error_description}).`); 
         this.logout();
       }
    });
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.auth0.logout({returnTo: process.env.REACT_APP_AUTH0_CALLBACK, clientID: process.env.REACT_APP_AUTH0_CLIENT_ID});
    
    //this.clearAuthAction();
  }
}

export default Auth;
