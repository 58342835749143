import Immutable from 'immutable';
import { CLEAR_AUTH, SET_AUTH } from './auth.actions';

const initialState = Immutable.Map({
  authSet: false,
  tokenInfo: null,
  roles: [],
  permissions: []
});

export default (state = initialState, action) => {
  switch (action.type) {
      case SET_AUTH:
      let rolesAttr = action.response['http://logic/roles'];
      let permissionsAttr = action.response['http://logic/permissions'];

      return state
        .set('authSet', true)
        .set('roles', rolesAttr)
        .set('permissions', permissionsAttr)
        .set('tokenInfo', action.response);

    case CLEAR_AUTH:
      return state
        .set('tokenInfo', null)
        .set('roles', [])
        .set('permissions', []);
    default:
      return state;
  }
};
