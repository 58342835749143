import { Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import classNames from 'classnames';
import { Person } from '@mui/icons-material';
import { cloneDeep, find, findIndex, forEach, hasIn, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../../auth/authOperations';
import { CREATE_DELEGATES } from '../../auth/permissions';
import { handleToastMessage } from '../../layout/layout.actions';
import Entities from './entities.component';
import DelegateModal from '@survey/common/dist/components/delegates/delegateModal.component';
import { addDelegate, removeDelegate, resendDelegate, setDelegates } from '@survey/common/dist/actions/delegates.actions';
import { submitSurvey, SUBMIT_SURVEY_SUCCESS } from '@survey/common/dist/actions/surveys.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import { hasDelegatePermission } from '@survey/common/dist/utilities/delegates';
import { getResource } from '@survey/common/dist/utilities/getResource';
// This provides the order in which the entity groupings should show and the name for the group
const orgTypeOrder = ['Health System', '1', '3', '2', '4', '14', 'Data Center'];

class SurveyLandingHomeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: [],
      facility: '',
      address: '',
      sendingEmail: false,
      surveyLoaded: false,
      showConfirmDialog: false,
      entities: [],
      status: '',
      open: false,
      showSubmitDialog: false,
    };

    ['handleFilterChange', 'handleDelegateModalClose', 'handleSendDelegates', 'handleRemoveDelegate', 'handleResendDelegate', 'onSubmit'].map((name) => (this[name] = this[name].bind(this)));
  }

  getRedirectURI() {
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';
    return isGermanTenderEnv ? 'https://www.digitalradar-krankenhaus.de/' : 'https://www.himss.org/';
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };

    if (!isEmpty(props.survey)) {
      const { questions, technologyQuestions, entities } = props.survey;
      const { status, facility } = state;
      const { auth, delegates, permissions, emptyEntities } = props;
      const entitiesMapped = {};

      let validEntities = entities.filter((e) => {
        if (emptyEntities.includes(e.entityID)) {
          return false;
        }

        return true;
      });
      let filteredEntities = validEntities;

      if (!hasPermission(CREATE_DELEGATES, permissions) && !hasDelegatePermission(delegates, auth.email)) {
        filteredEntities = entities.filter((entity) => {
          if (hasDelegatePermission(delegates, auth.email, entity.entityID)) {
            return true;
          } else {
            const entityDelegates = find(delegates.entities, { entityID: entity.entityID });

            return (
              entityDelegates &&
              entityDelegates.categories &&
              find(entityDelegates.categories, (page) => {
                return find(page.users, { email: auth.email }) ? true : false;
              })
            );
          }
        });
      }

      newState.questions = questions;
      newState.technologyQuestions = technologyQuestions;

      if (status !== '') {
        filteredEntities = filteredEntities.filter((entity) => (entity.status !== null ? entity.status.toLowerCase().includes(status.toLowerCase()) : false));
      }

      if (facility !== '') {
        filteredEntities = filteredEntities.filter((entity) => (entity.entityName !== null ? entity.entityName.toLowerCase().includes(facility.toLowerCase()) : false));
      }

      filteredEntities.forEach((entity) => {
        switch (entity.haEntityTypeID) {
          case 6:
          case 8:
            !hasIn(entitiesMapped, 'Health System') ? (entitiesMapped['Health System'] = [entity]) : entitiesMapped['Health System'].push(entity);
            break;

          case 9:
          case 10:
            !hasIn(entitiesMapped, 'Data Center') ? (entitiesMapped['Data Center'] = [entity]) : entitiesMapped['Data Center'].push(entity);
            break;

          default:
            !hasIn(entitiesMapped, entity.haEntityTypeID) ? (entitiesMapped[entity.haEntityTypeID] = [entity]) : entitiesMapped[entity.haEntityTypeID].push(entity);
            break;
        }
      });

      newState.entities = entitiesMapped;
    }

    return newState;
  }

  generateSurveyOrgCards(entities, props) {
    const { organizationTypes, classes, surveyType, auth, permissions, delegates, survey, pages, tabs, technologies, resources } = props;
    const entityCards = [];

    forEach(orgTypeOrder, (orgType) => {
      let orgName = '';
      let userEntities = [];

      if (delegates.entities?.length > 0 && JSON.stringify(delegates.entities).indexOf(auth.email) > -1) {
        userEntities = delegates.entities.filter(x => JSON.stringify(x).indexOf(auth.email) > -1).map(x => x.entityID);
      }

      if (entities[orgType] && (userEntities.length === 0 || entities[orgType].some(x => userEntities.includes(x.entityID)))) {
        if (!isNaN(Number(orgType))) {
          const organizationType = find(organizationTypes, (type) => Number(orgType) === type.organizationTypeID);
          if (organizationType) orgName = organizationType.organizationTypeName;
        }

        let filteredEntities = entities[orgType].sort((a, b) => (a.entityName > b.entityName ? 1 : -1));

        if (userEntities.length > 0) {
          filteredEntities = filteredEntities.filter(x => userEntities.includes(x.entityID));
        }

        const entityCard = (
          <Paper key={orgType} className={classNames(classes.orgPaper, 'mobiOrgPaper')}>
            <Typography className={classes.orgName} variant="h6">
              {orgName ? getResource(resources, survey.language, 'Label', orgName) : getResource(resources, survey.language, 'Label', orgType)}
            </Typography>
            <Entities
              entities={filteredEntities}
              survey={survey}
              surveyType={surveyType}
              auth={auth}
              permissions={permissions}
              delegates={delegates}
              pages={pages}
              tabs={tabs}
              technologies={technologies}
              resources={resources}
            />
          </Paper>
        );

        entityCards.push(entityCard);
      }
    });

    return entityCards;
  }

  handleFilterChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleDelegateModalClose() {
    this.setState({ open: false, sendingEmail: false });
  }

  async handleSendDelegates(newDelegates, sendPlainText) {
    const { addDelegate, survey, setDelegates, delegates } = this.props;
    const delegatesClone = cloneDeep(delegates);
    
    this.setState({ sendingEmail: true });
    
    console.log('in handleSendDelegates in surveyLandingHome.container, sendPlainText: ', sendPlainText);

    for (const [index, d] of newDelegates.entries()) {
      delete d.accessDelegated;
      const response = await addDelegate(survey.surveyID, d, sendPlainText);

      if (response.type === 'ADD_DELEGATE_SUCCESS') {
        if (isEmpty(delegatesClone.users)) {
          delegatesClone.users = [d];
        } else {
          delegatesClone.users.push(d);
        }

        if (index + 1 === newDelegates.length) {
          await setDelegates(delegatesClone);
        }
      }
    }

    this.setState({ open: false, sendingEmail: false });
  }

  async handleRemoveDelegate(delegate) {
    const { survey, removeDelegate, setDelegates, delegates } = this.props;
    const delegatesClone = cloneDeep(delegates);

    //delete delegate.accessDelegated;
    //delete delegate.pageID;
    console.log('SLHC removing delegate: ', delegate);
    console.log('SLHC survey.surveyID: ', survey.surveyID);
    const response = await removeDelegate(survey.surveyID, delegate);

    if (response.type === 'REMOVE_DELEGATE_SUCCESS') {
      if (!hasIn(delegate, 'entityID')) {
        const userIndex = findIndex(delegates.users, { email: delegate.email });

        delegatesClone.users.splice(userIndex, 1);
      } else if ('pageID' in delegate) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const pageIndex = findIndex(delegatesClone.entities[entityIndex].categories, { pageID: delegate.pageID });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].categories[pageIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].categories[pageIndex].users.splice(userIndex, 1);
      } else if ('entityID' in delegate) {
        const entityIndex = findIndex(delegatesClone.entities, { entityID: delegate.entityID });
        const userIndex = findIndex(delegatesClone.entities[entityIndex].users, { email: delegate.email });

        delegatesClone.entities[entityIndex].users.splice(userIndex, 1);
      }

      await setDelegates(delegatesClone);
    }
  }

  async handleResendDelegate(delegate, delegateModalDelegates, delegateModalSurvey, plainText) {
    const { survey, resendDelegate } = this.props;

    console.log('in handleResendDelegate in surveyLandingHome.container, plainText: ', plainText);

    delete delegate.accessDelegates;
    const response = await resendDelegate(survey.surveyID, delegate, plainText);

    if (response.type === 'RESEND_DELEGATE_SUCCESS') {
      console.log('SLH.container, DELEGATE RESEND SUCCESS');
      this.setState({ showConfirmDialog: true });
    }
  }

  async onSubmit() {
    const { survey, resources } = this.props;

    /* Hide the dialog */
    this.setState({ showSubmitDialog: false });
    var self = this;
    /* Save the survey */
    let allowSubmit = ['Not Started', 'In Progress'];
    if(!allowSubmit.includes(survey.status)){
      this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Failed to submit survey!'), true);
      return;
    }
    
    const response = await this.props.submitSurvey(this.props.survey.surveyGuid);
    if (response.type === SUBMIT_SURVEY_SUCCESS) {
      this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Survey successfully submitted.'), false);
      setTimeout(function () {
        window.location.replace(self.getRedirectURI());
      }, 3000); //I think this redirects them home, and when they land home, it runs through welcome component, which defaults to step 0 which shows the screen we see.
    } else {
      this.props.handleToastMessage(getResource(resources, survey.language, 'Message', 'Failed to submit survey!'), true);
    }
  }

  render() {
    const { classes, survey, auth, delegates, permissions, resources } = this.props;
    const { facility, open, entities, sendingEmail } = this.state;
    let orgCards = [];

    if (delegates.surveyID && delegates.surveyID !== 0) {
      orgCards = this.generateSurveyOrgCards(entities, this.props);
    }

    var langVal = survey.language === 'British English' ? 'English' : survey.language === '' || survey.language === null ? 'English' : survey.language;
    return (
      <Fragment>
        <Grid className="mobifullwidth" container spacing={6} alignItems="center">
          <Grid item xs={3} className="mobifullwidth">
            <TextField
              label={getResource(resources, survey.language, 'Label', 'Facility')}
              value={facility}
              name="facility"
              onChange={(e) => this.handleFilterChange(e)}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              margin="dense"
              className={classNames(classes.textField, 'lhomefacilityfilter')}
              fullWidth
            />
          </Grid>
          <Grid className={classNames(classes.actionContainer, 'mobifullwidth', 'mobiheaderbuttons')} item xs={9}>
            <Fragment>
              { this.props.surveyType.name != "Satisfaction Survey" && 
                <Button
                  color="primary"
                  variant={isEmpty(delegates.users) || delegates.users.length === 0 ? 'contained' : 'outlined'}
                  className={classNames(classes.delegateButton, 'lhomedelegatebutton')}
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  startIcon={this.props.isLoading ? <CircularProgress size="16px" /> : null}
                >
                  <Person style={{ marginRight: '.25rem' }} />
                  {!isEmpty(delegates.users)
                    ? `${delegates.users.length} ${
                        getResource(resources, survey.language, 'Label', 'DELEGATE(S)')
                      }`
                    : getResource(resources, survey.language, 'Label', 'Global Delegate')}
                </Button>
              }
              { process.env.REACT_APP_ENVIRONMENT !== "germantender" && survey.language !== "Spanish" &&
              (
               this.props.surveyType.name == "EMRAM 2022" || 
               this.props.surveyType.name == "CCMM" || 
               this.props.surveyType.name == "C-COMM" ||
               this.props.surveyType.name == "INFRAM 2023" ||
               this.props.surveyType.name == "INFRAM 2024" ||
               this.props.surveyType.name == "AMAM 2024"
              ) &&
                <a href={'https://ha-static-content.s3.amazonaws.com/SurveyFAQs/Maturity_Model_Platform_FAQ_' + langVal + '.pdf'} target="_blank" rel="noopener noreferrer" >
                  <Button color="primary" variant="contained" style={{ padding: '.375rem .75rem', marginRight: '1rem'}}>
                    {getResource(resources, survey.language, 'Label', 'FAQ')}
                  </Button>
                </a>
              }
              <Button
                color="primary"
                variant="contained"
                style={{ padding: '.375rem .75rem' }}
                onClick={() => {
                  this.setState({ showSubmitDialog: true });
                }}
                className={'lhomesubmitbutton'}
              >
                {getResource(resources, survey.language, 'Label', 'Submit Survey')}
              </Button>
              <Confirm
                title={getResource(resources, survey.language, 'Message', 'Submit Complete Survey?')}
                onClose={() => this.setState({ showSubmitDialog: false })}
                onConfirm={() => this.onSubmit()}
                contentText={
                  process.env.REACT_APP_ENVIRONMENT !== "germantender" ?
                    getResource(resources, survey.language, 'msgSubmit', '') :
                    "Hiermit erkläre ich, die Fragen der Evaluierung nach bestem Wissen und Gewissen beantwortet zu haben. Die übermittelten Daten entsprechen dem Stand der digitalen Reife unseres Krankenhauses zum vorgegebenen Stichtag. Die Geschäftsleitung bzw. der Vorstand unserer Einrichtung ist über Art, Umfang und Inhalt der übermittelten Daten informiert. Des Weiteren bestätige ich, die Angaben im Namen des Krankenhauses treffen und übermitteln zu dürfen.<br /><br />" +
                    "Hinweis, falls Ihnen diese Umfrage delegiert wurde: bitte stimmen Sie sich vor der Übermittlung der Daten mit dem Hauptverantwortlichen für die Reifegradermittlung in Ihrer Organisation ab. Nach der Bestätigung wird die Erhebung für alle Sektionen / Zugriffsberechtigten abgeschlossen."
                }
                cancelText={getResource(resources, survey.language, 'Label', 'Cancel')}
                confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
                open={this.state.showSubmitDialog}
              />
            </Fragment>
          </Grid>
        </Grid>
        {orgCards.length ? (
          orgCards
        ) : delegates.surveyID && delegates.surveyID !== 0 ? (
          <div>
            <br />
            <Typography variant="h4" style={{ marginTop: '2em', textAlign: 'center' }} className={classes.thankYou}>
              No organizations found.
            </Typography>
          </div>
        ) : ( <div></div> )}
        <DelegateModal
          auth={auth}
          delegates={delegates}
          handleClose={this.handleDelegateModalClose}
          handleRemove={this.handleRemoveDelegate}
          handleResend={this.handleResendDelegate}
          handleSend={this.handleSendDelegates}
          sendingEmail={sendingEmail}
          open={open}
          survey={survey}
          resources={resources}
          language={survey.language}
        />
        <Confirm
          title={getResource(resources, survey.language, 'Message', 'Email Resent to Delegate')}
          buttons="confirm-only"
          onClose={() => this.setState({ showConfirmDialog: false })}
          onConfirm={() => this.setState({ showConfirmDialog: false })}
          contentText={getResource(resources, survey.language, 'Message', 'An email has been re-sent to the delegate.')}
          open={this.state.showConfirmDialog}
          confirmText={getResource(resources, survey.language, 'Label', 'Confirm')}
        />
      </Fragment>
    );
  }
}

const styles = () => ({
  cardsArea: {
    marginTop: '2rem !important',
  },
  thankYou: {
    color: '#888784 !important',
  },
  orgPaper: {
    marginTop: '2rem !important',
    padding: '1rem 1.5rem !important',
  },
  orgName: {},
  delegateButton: {
    marginRight: '1rem !important',
    minWidth: '8.25rem !important',
    padding: '.375rem .75rem !important',
  },
  actionContainer: {
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    marginTop: '0.5rem !important',
  },
});

SurveyLandingHomeContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  delegates: PropTypes.object.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  permissions: PropTypes.array,
  questions: PropTypes.array,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    organizationTypes: state.organizations.get('organizationTypes'),
    questions: state.questions.get('questions'),
    isLoading:
      state.products.get('isLoading') ||
      state.vendors.get('isLoading'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    addDelegate,
    removeDelegate,
    resendDelegate,
    setDelegates,
    submitSurvey,
    handleToastMessage,
  })(SurveyLandingHomeContainer)
);
